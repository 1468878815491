<!--
   User: Liu Yin
   Date: 2020/3/12
   Description:资讯专栏
 -->
<template>
  <article class="online-root">
    <div v-if="!showDetail">
      <swiperbanner class="banner" :dict-code="Banner.BANNER_INFORMATION"></swiperbanner>
      <div class="ceil">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">最新活动</el-menu-item>
          <el-menu-item index="2">业务动态</el-menu-item>
          <el-menu-item index="3">信息资讯</el-menu-item>
        </el-menu>
      </div>
      <div id="activity" ref="activity">
        <el-row :gutter="10" class="flexcencen">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <!-- 最新活动 -->
            <div class="title"><span>最新活动</span></div>
            <div class="box">
              <activity @handleDetail="handleDetail"></activity>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="businessDynamics" ref="businessDynamics">
        <el-row :gutter="10" class="flexcencen">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="title"><span>业务动态</span></div>
            <!-- 业务动态 -->
            <div class="box">
              <business-dynamics @handleDetail="handleDetail"></business-dynamics>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="policyList" ref="policyList">
        <el-row :gutter="10" class="flexcencen">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="title"><span>信息资讯</span></div>
            <!-- 信息资讯 -->
            <div class="box">
              <policyList @handleDetail="handleDetail"></policyList>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <policy-detail v-if="showDetail" :row-id="rowId" @close="handleClosePolicy"></policy-detail>
  </article>
</template>
<script>

import listMixin from '../../../../utils/mixins/listMixin'
import policyList from './components/policyList'
import policyDetail from './policyDetail'
import businessDynamics from './components/businessDynamics'
import activity from './components/activity'
import Swiperbanner from '../../../../components/swiperbanner.vue'
import { Banner } from '../../../../utils/dict'

export default {
  components: {
    policyList,
    businessDynamics,
    activity,
    policyDetail,
    Swiperbanner
  },
  mixins: [listMixin],
  data() {
    return {
      Banner,
      activeIndex: '1',
      activeName: 'first',
      rowId: null
    }
  },
  mounted() {
    document.body.scrollTop = 0
    window.addEventListener('scroll', this.handleScroll, true)
    document.getElementById('app').scrollTop = 0
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll() {
      // console.log(document.documentElement.scrollTop, document.body.scrollTop)
      // const ch = document.getElementById('app').scrollTop
      const ch = document.body.scrollTop
      if (document.getElementById('activity')) {
        const activity = document.getElementById('activity').offsetTop
        const businessDynamics = document.getElementById('businessDynamics').offsetTop
        const policyList = document.getElementById('policyList').offsetTop
        if (ch >= activity && ch < businessDynamics) {
          this.activeIndex = '1'
        } else if (ch >= businessDynamics && ch < policyList) {
          this.activeIndex = '2'
        } else if (ch >= policyList) {
          this.activeIndex = '3'
        }
      }
    },
    handleSelect(key, keyPath) {
      console.log(key)
      if (key === '1') {
        const el = document.getElementById('activity')
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      } else if (key === '2') {
        const el = document.getElementById('businessDynamics')
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      } else if (key === '3') {
        const el = document.getElementById('policyList')
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      }
    },
    // 查看详情
    handleDetail(item) {
      this.rowId = item.inid
      if (item.contentType === 2) {
        /* //window.location.href = item.linkurl*/
        window.open(item.linkurl)
      } else {
        const routeData = this.$router.resolve({
          path: '/policy-m', query: {
            rowId: item.inid
          }
        })
        window.open(routeData.href, '_blank')
        // this.showDetail = true
      }
      // this.setScrollPosition()
    },
    // 关闭新闻政策
    handleClosePolicy() {
      this.showDetail = false
      // this.scrollToPosition()
    }
  }
}
</script>

<style lang="scss" scoped>
.online-root {
  // overflow-x: hidden;
  // width: 67%;

  margin: auto;
}
.flexcencen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  max-width: 1280px;
  margin: 30px auto;
}
.el-menu-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 3px solid #409eff;
  color: #303133;
  font-weight: 900;
  font-size: 16px;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  // width: 100%;
  color: #252b3a;
  // font-size: 24px;
  // line-height: 30px;
  text-align: center;
  padding: 30px 0px 0px 0px;
  // font-weight: 600;
  width: 100%;
  line-height: 46px;
  font-size: 24px;
  font-weight: inherit;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}
::v-deep .el-tabs__item {
  font-size: 18px;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
  font-weight: 900;
}
::v-deep .el-tabs__active-bar {
  height: 3px;
}
.ceil {
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
}
.banner {
  width: 100%;
  height: 430px;
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
</style>
