<template>
  <article class="online-root">
    <div v-if="newsList.length>0">
      <el-row :gutter="20">
        <el-col
          v-for="(item, index) in newsList"
          :key="index"
          :xs="12"
          :sm="8"
          :md="6"
          :lg="6"
          :xl="6"
          class="item-container"
        >
          <div style="position: relative">
            <el-card :body-style="{ padding: '0px' }">
              <article class="item-parent" @click="handleDetail(item)">
                <!-- <div class="item-top" :style="[getBackgroundStyle(item)]"></div> -->

                <div class="item-top">
                  <img :src="getImg(item.titlePicture)" @error="imgError">
                <!-- <div v-if="item.collation!==0" class="keynote">{{ convert(item.collation) }}</div> -->
                </div>

                <div class="item-bottom">
                  <div class="item-name" :title="item.inotitle">
                    {{ item.inotitle }}
                  </div>
                  <div class="news-item-bottom">
                    <span class="bottom-item">{{ item.publishtime }}</span>
                  </div>
                <!-- <p style="margin-top: 11px" class="item-content second-line">发布时间: {{ item.shareTime | moment('YYYY-MM-DD HH:mm:ss') }} </p> -->
                </div>
              </article>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <div style="text-align: center;">
        <el-pagination class="pageAction" :current-page.sync="currentPage" :page-size="size" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
      </div>
    </div>
    <el-empty v-else description="暂无数据"></el-empty>
  </article>
</template>

<script>
import listMixin from '../../../../../utils/mixins/listMixin'

import backgroundImage from '../../../../../assets/images/talent/appraise-top.png'
export default {
  components: {

  },
  mixins: [listMixin],
  data() {
    return {
      newsList: [],
      size: 8
    }
  },
  mounted() {
    this.requestData()
  },
  methods: {
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.toggleLoading(true)
      this.searchForm.inotype = 'new_information'
      this.$get(this.urls.informationList, this.searchForm, [this.currentPage, this.size]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.newsList = data.records
          this.total = data.total
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    // 查看详情
    handleDetail(item) {
      this.$emit('handleDetail', item)
    }

  }
}
</script>

<style lang="scss" scoped>

.item-container {
  // width: 25%;
  // box-sizing: border-box;
  padding: 8px;
  min-height: 235px;

  .item-parent {
    cursor: pointer;
    transition: all 0.6s;
    min-height: 235px;
    .masking::before {
      content: "";
      // background-color: rgba(0,0,0,.5);
      background: linear-gradient(
        0deg,
        #000000d9,
        rgb(0 0 0 / 69%),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.17),
        hsla(0, 0%, 100%, 0)
      );
      width: 100%;
      height: 35px;
      position: absolute;
      bottom: 2px;
      left: 0;
    }
    .item-top {
      position: relative;
      height: 71.8%;
      width: 100%;

      img {
        background-image: url("../../../../../assets/images/appraise-top.png");
        background-size: 100% 100%;
        background-position: 50% 50%;
        width: 100%;
        height: 170px;
        overflow: hidden;
      }
    }
    .item-bottom {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      margin: 1.5% 0;
      .label-parent {
        margin-top: 20px;
      }
      .item-name {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 230px;
      }
      .item-speaker {
      }
      .item-content {
        margin-top: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: rgb(135, 135, 135);
      }
      .label {
        background-color: rgb(231, 146, 71);
        padding: 3px 10px;
        color: white;
        font-size: 17px;
      }
    }
  }
}
.news-item-bottom{
  font-size: 12px;
    color: #575d6c;
    line-height: 26px;
}
</style>
