<template>
  <article class="online-root">
    <div v-if="newsList.length>0" class="policyList">
      <ul>
        <li v-for="(item,index) in newsList" :key="index" @click="handleDetail(item)">
          <article class="el-news-right-item">
            <div class="el-news-list-card">
              <img :src="getImg(item.titlePicture)" @error="imgError">
              <div>
                <span class="news-item-title">
                  {{ item.inotitle }}
                </span>
                <p class="news-item-content">
                  {{ item.validity }}
                </p>
                <div class="news-item-bottom">
                  <span class="bottom-item">{{ item.publishtime }}</span>
                </div>
              </div>
            </div>
          </article>
        </li>
      </ul>
      <div style="text-align: center;">
        <el-pagination class="pageAction" :current-page.sync="currentPage" :page-size="size" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
      </div>
    </div>
    <el-empty v-else description="暂无数据"></el-empty>
  </article>
</template>

<script>
import listMixin from '../../../../../utils/mixins/listMixin'
import backgroundImage from '../../../../../assets/images/talent/appraise-top.png'
export default {
  components: {
  },
  mixins: [listMixin],
  data() {
    return {
      newsList: []
    }
  },
  mounted() {
    this.requestData()
  },
  methods: {
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.searchForm.inotype = 'trends_information'
      this.toggleLoading(true)
      this.$get(this.urls.informationList, this.searchForm, [this.currentPage, this.size]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.newsList = data.records
          this.total = data.total
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    // 查看详情
    handleDetail(item) {
      this.$emit('handleDetail', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.news-item-title{
  color: #252b3a;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    max-width: 744px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}
.news-item-content{
  color: #575d6c;
    font-size: 14px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 44px;
    width: 825px;
}
.news-item-bottom{
  color: #575d6c;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
}
.el-news-right-item{
      display: block;
    justify-content: space-between;
    transition: 0.2s;
    position: relative;
    height: inherit;
}
.el-news-list-card{
  height: 120px;
    padding: 15px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    img{
          width: 50px;
    height: 50px;
    margin-right: 36px
    };
}
.policyList{
  ul{
    // border-top: 1px solid #ededed;
  }
// ul li:nth-child(odd) {
// background-color: #f7f7f7;
// }
// ul li:nth-child(even) {
// background-color: #fff;
// }
li{
  background: #fff;
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 10%);
  margin-top: 10px;
}
}
ul li:hover{
  transition: all 0.2s ease;
  transform: translateY(-3%);
      // box-shadow: 0 2px 20px 0 rgb(0 0 0 / 15%);
      box-shadow: 0 12px 9px 0 rgb(0 0 0 / 20%);
}
</style>
